import {DatePipe} from '@angular/common';
import {ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, LazyLoadEvent, MenuItem, Message, MessageService, PrimeNGConfig} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Dossier} from 'src/app/core/models/dossiers/dossier';
import {ItemCriteriaResarchDossier} from 'src/app/core/models/dossiers/item-criteria-resarch-dossier';
import {ResponsePagination} from 'src/app/core/models/dossiers/response-pagination';
import {DossierService} from 'src/app/core/service/dossier.service';
import {LocalStorageRechercheDossierDemandeurService} from 'src/app/core/service/local-storage/local-storage-recherche-dossier-demandeur.service';
import {LocalStorageService} from 'src/app/core/service/local-storage/local-storage.service';
import {TypeLivrablePipe} from 'src/app/shared/pipes/type-livrable.pipe';
import {Validation} from 'src/app/shared/providers/validation';
import {TranslateService} from 'src/app/shared/translate/translate.service';
import {AffectationRechercheAvanceeExpertComponent} from "../../../core/dialog/affectation-recherche-avancee-expert/affectation-recherche-avancee-expert.component";
import {StatutDossierPipe} from "../../../shared/pipes/statut-dossier.pipe";
import {AppSettings, Settings} from "../../../app.settings";
import {UserService} from "../../../core/service/user/user.service";
import {Pagination} from 'src/app/core/models/rechercher-tableau/pagination';
import {DossierFilter} from 'src/app/core/models/rechercher-tableau/dossier-filter';
import {PaginationComponent} from 'src/app/shared/pagination/pagination.component';
import {User} from "../../../core/models/user/user.model";
import {ProfilesUser} from "../../../core/models/user/profiles-user";
import {NgxSpinnerService} from "ngx-spinner";
import {ConfigConstants} from 'src/app/core/models/enum/config-constants';
import {Signataire} from "../../../core/models/user/signataire";
import {UtilsFonction} from "../../../shared/providers/utils-fonction";
import {StatutDossier} from "../../../core/models/enum/statut-dossier";
import {RejetLivrableComponent} from "../../../core/dialog/rejet-livrable/rejet-livrable.component";
import {DuplicationDossierComponent} from "../../../core/dialog/duplication-dossier/duplication-dossier.component";


@Component({
  selector: 'recherche-dossier-gestionnaire',
  templateUrl: './recherche-gestionnaire.component.html',
  styleUrls: ['./recherche-gestionnaire.component.scss'],
  providers: [MessageService]
})
export class RechercheDossierGestionnaireComponent implements OnInit {

  ConfigConstants = ConfigConstants;

  dossiers: Dossier[] = [];

  criteres: ItemCriteriaResarchDossier;

  detailToast: string;

  totalRecords : number = 0;

  loading: boolean;

  header : any;
  infoBulleConflit: any;

  listFolderEmpty : boolean = false;
  display: boolean = false;

  ref: DynamicDialogRef;

  response : ResponsePagination;
  @ViewChild(PaginationComponent) pagination : PaginationComponent;

  message: Message[];


  ecran = 'GESTIONNAIRE';

  tableHeight : number;

  public settings: Settings;

  titleHeader : string;

  firstLazyLoad : boolean = true;

  dossierToRejected : Dossier;

  messageReject : Message[];

  userConnecte : User;
  role : any[];

  userSignataires : Signataire[];

  constructor(
    private dossierService: DossierService,
    private messageService: MessageService,
    private storageCritereRecherche : LocalStorageRechercheDossierDemandeurService,
    private tokenStorgeService : LocalStorageService,
    public translate: TranslateService,
    public dialogService: DialogService,
    private datePipe : DatePipe,
    private route : ActivatedRoute,
    private primengConfig: PrimeNGConfig,
    public appSettings : AppSettings,
    private confirmation: ConfirmationService,
    private router : Router, private userService : UserService,
    private cdRef: ChangeDetectorRef,  private spinnerService: NgxSpinnerService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {

    this.primengConfig.ripple = true;

    this.header = this.translate.data.DOSSIER.GESTIONNAIRE.HEADER.TITLE;
    this.infoBulleConflit = this.translate.data.DOSSIER.GESTIONNAIRE.INFO.CONFLIT;

    this.quickSearch();

    this.getUserConnected();

    this.getAllUtilisateurSignataires();

  }

  /**
   * Pour la gestion automatique de la taille du tableau
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    let elem = document.getElementById('tableaux');
    let rect = elem?.getBoundingClientRect();
    this.tableHeight = window.innerHeight - rect?.y;
  }

  async quickSearch() {
    const profiles = await this.userService.getUserConnectedProfilesById();
    if (profiles) {
      this.route.queryParams.subscribe(data => {
        if(data && data.typeRecherche === "rechercheRapide") {
          this.criteres = new ItemCriteriaResarchDossier(data);
          this.criteres.typeVue = profiles
          this.titleHeader = this.translate.getElement('DOSSIER.RECHERCHER');
        } else if(data && data.typeRecherche === "dashboardCard") {

          this.criteres = new ItemCriteriaResarchDossier();

          if(data?.etatEnvoi === ConfigConstants.ETAT_ENVOI_PROJET) {
             this.criteres.etatEnvoi = ConfigConstants.ETAT_ENVOI_PROJET;
          }

          this.criteres.typeVue = profiles
          this.criteres.statutDossier = [StatutDossier.D_ATTENTE_V1];
          this.titleHeader = this.translate.getElement('DOSSIER.GESTIONNAIRE.HEADER.TITLE');
        }else {
          if(this.storageCritereRecherche.getResponseRechercheDossierDemandeur()) {
            this.criteres = JSON.parse(this.storageCritereRecherche.getResponseRechercheDossierDemandeur());
          }
          this.titleHeader = this.translate.getElement('DOSSIER.RECHERCHER');
        }
        let page : Pagination = this.criteres.pagination;
        if (page == null) {
          page = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows, "numero", "asc");
        }
        this.getListDossierGestionnaire(this.criteres, page, true);
      });
    }
  }

  async getUserConnected() {
    let profiles = await this.userService.getUserConnectedProfilesById();
    this.userConnecte = await this.userService.getUserConnectedByToken();
    this.role = Object.values(profiles).map(item => String(item));
    if(this.userConnecte) {
      this.userConnecte.profiles = profiles;
    }
  }

  getAllUtilisateurSignataires() {
    this.dossierService.getAllUtilisateurSignataires().subscribe( data => {
      this.userSignataires = data;
    })
  }

  /**
   * Gestion des actions utilisateur sur chaque dossier en fonction de son statut
   * @param dossier
   */
  getActions(dossier: Dossier): MenuItem[] {

    let conflit : boolean = dossier.bien?.enConflit;

    let itemRepasserAAffecter: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.REPASSER_AFFECTER"), icon :'inc-icon inc-icon-flag is-small',
      visible : !conflit,
      command: () => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header : this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.REPASSER_AFFECTER"),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.repasserAAffecter(dossier);
          }
        })
      }
    };

    let itemAffecter: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.AFFECTER"), icon :'inc-icon inc-icon-bell is-small',
      visible : !conflit,
      command: () => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header : this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.AFFECTER"),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.affecter(dossier);
          }
        })
      }
    };

    let itemRelancer: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.RELANCER"), icon:'inc-icon inc-icon-arrow is-small',
      visible : !conflit,
      command: () => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header : this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.RELANCER"),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.relancer(dossier);
          }
        })
      }
    };

    let itemDupliquer: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.DUPLIQUER"), icon : 'pi pi-copy',
      visible : !conflit,
      command: () => {
        this.ref = this.dialogService.open(DuplicationDossierComponent, {
          header: this.translate.getElement('DOSSIER.DUPLIQUER_DOSSIER',   dossier.numeroDossier),
          width: '30vw',
          height: '84%',
          baseZIndex: 10000,
          data : { dossier : dossier, user : this.userConnecte},
          styleClass : 'p-dynamic-dialog'
        });

        this.ref.onClose.subscribe((data : any) =>{
          if (data && data.valueTypeLivrable)  {
            let idAgence : number = 0;
            if(data.agent != null) {
              idAgence = data.agent?.id;
            }
            this.dupliquer(dossier, data.valueTypeLivrable, idAgence);
          }
        });
      }
    };

    let itemModifierLivrable: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.MODIFIER_LIVRABLE"), icon :'inc-icon inc-icon-edit is-small',
      visible : !conflit,
      command: () => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header : this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.MODIFIER_LIVRABLE"),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.modifierLivrable(dossier);
          }
        })
      }
    };

    let itemRejeterLivrable: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.REJETER"), icon :'inc-icon inc-icon-close is-small',
      visible : !conflit,
      command: () => {
        if(!dossier.synthese) {
          this.confirmation.confirm({
            message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
            header : this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.REJETER'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
              dossier.etatEnvoi = ConfigConstants.ETAT_ENVOI_INIT;
              this.rejeterLivrable(dossier);
            }
          });
        }else {
          this.ref = this.dialogService.open(RejetLivrableComponent, {
            header: this.translate.getElement('DOSSIER.GESTIONNAIRE.ACTION.REJETER'),
            width: '30vw',
            baseZIndex: 10000,
            data : dossier,
            styleClass : 'p-dynamic-dialog'
          });

          this.ref.onClose.subscribe((data : string) =>{
            if (data && (data === ConfigConstants.ETAT_ENVOI_INIT || data === ConfigConstants.ETAT_ENVOI_SYNTHESE) ){
                dossier.etatEnvoi = data;
                this.rejeterLivrable(dossier);
            }
          });
        }

      }
    };

    let itemControleLivrable: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.CONTROLE"),
      icon :'pi pi-lock',
      visible : !conflit,
      command: () => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header : this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.CONTROLE"),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.controlerLivrable(dossier);
          }
        })
      }
    };

    let itemAnnuler: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.ANNULER"), icon :'inc-icon inc-icon-close is-small',
      command: () => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header :this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.ANNULER"),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.annuler(dossier);
          }
        })
      }
    };

    let itemReactiver: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.REACTIVER"), icon :'inc-icon inc-icon-load is-small',
      command:  event => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header : this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.REACTIVER"),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.reactiver(dossier);
          }
        })
      }
    };

    let itemDebloquerDossier: MenuItem = {
      label: this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.DEBLOQUER_DOSSIER"),
      icon :'pi pi-lock-open',
      visible : !conflit,
      command: () => {
        this.confirmation.confirm({
          message: this.translate.getElement("DOSSIER.GESTIONNAIRE.MESSAGE.ACTION_CONFIRMATION"),
          header : this.translate.getElement("DOSSIER.GESTIONNAIRE.ACTION.DEBLOQUER_DOSSIER"),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.debloquerDossierVerouiller(dossier);
          }
        })
      }
    };

    if (dossier.statut == null) {
      return [];
    }

    if (dossier.statut === StatutDossier.A_AFFECTER) {
      return [itemAffecter, itemDupliquer, itemAnnuler];
    }

    if (dossier.statut === StatutDossier.ENCOURS) {
      return [itemRepasserAAffecter, itemRelancer, itemDupliquer, itemAnnuler];
    }
    if (dossier.statut === StatutDossier.A_FACTURER) {
      return [itemDupliquer, itemModifierLivrable, itemRejeterLivrable, itemAnnuler];
    }

    if (dossier.statut === StatutDossier.FACTUREE) {
      return [itemDupliquer, itemModifierLivrable];
    }

    if (dossier.statut === StatutDossier.F_ENCOURS) {
      return [itemDupliquer, itemModifierLivrable];
    }

    if (dossier.statut === StatutDossier.ANNULE) {
      return [itemReactiver];
    }

    if (dossier.statut === StatutDossier.D_ATTENTE_V1) {
      const isUserAuthorized =
        (this.userSignataires?.find(us => us.id === this.userConnecte.id && us.actif) &&
          (this.userConnecte.profiles.includes(ProfilesUser.ASSISTANTE) || this.userConnecte.profiles.includes(ProfilesUser.ANIMATEUR_RESEAU))) ||
        (this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR) || this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE));

      const additionalItems = [
        itemDupliquer,
        itemModifierLivrable,
        itemRejeterLivrable,
        itemAnnuler
      ];

      if (isUserAuthorized) {
        if (this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR) || this.userConnecte.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)) {
          additionalItems.splice(3, 0, dossier.dossierIsLoked ?  itemDebloquerDossier : itemControleLivrable);
        } else {
          const isLockedByCurrentUser = dossier.dossierIsLoked && this.userConnecte.id === dossier.validationVerrouilleur && dossier.dateValidationVerrouillage;

          additionalItems.splice(3, 0, isLockedByCurrentUser ? itemDebloquerDossier : itemControleLivrable);
        }
      }

      return additionalItems;
    }

    return [];
  }

  /**
   * Reppaser un dossier en etat affecter
   * @param dossier
   */
  repasserAAffecter(dossier: Dossier) {
    this.loading = true;
    this.dossierService.repasserAAffecter(dossier).subscribe(response => {
      this.message = [
        {severity:'success', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.SUCCES_REPASSER_AFFECTER', dossier.numeroDossier.toString()) }
      ];
      this.getListDossierGestionnaire(this.criteres, this.criteres.pagination);
      this.loading = false;
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.ERROR_REPASSER_AFFECTER',  dossier.numeroDossier.toString())}
      ];
      this.loading = false;
    })
  }

  /**
   * Affecter un dossier à un expert
   * @param dossier
   */
  affecter(dossier: Dossier) {
    this.loading = true;
    this.ref = this.dialogService.open(AffectationRechercheAvanceeExpertComponent, {
      header: this.translate.getElement('DOSSIER.AFFECTATION.HEADER.AFFECTER'),
      width: '400px',
      baseZIndex: 10000,
      data : 'RECHERCHE_AVANCEE_STATUT_ACTION',
      styleClass : 'p-dynamic-dialog'
    });

    this.ref.onClose.subscribe((data : any) =>{
      if (data) {
        this.spinnerService.show();
        const expert = data.expert;
        const assistante = data.assistante;
        if(dossier && expert.id && assistante.id) {
          let idDossiers : number[] = [dossier.idDossier];
          this.dossierService.affectationDossier(idDossiers, expert.id, assistante.id).subscribe(data => {
            if(data) {
              this.message = [
                {severity:'success', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.AFFECT', expert.nomComplet, assistante.nomComplet ) }
              ];
              this.getListDossierGestionnaire(this.criteres, this.criteres.pagination);
            }
            this.spinnerService.hide();
          }, error => {
            this.message = [
              {severity:'error', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.NOTAFFECT')}
            ];
            this.spinnerService.hide();
          });
        }
      }
      this.loading = false;
    });
  }

  /**
   * Relancer le dossier
   * @param dossier
   */
  relancer(dossier: Dossier) {
    this.loading = true;
    this.dossierService.relancerDossier(dossier.idDossier).subscribe(data => {
      if (data) {
        this.message = [
          {severity:'success', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.RELANCE.SUCCES', dossier.numeroDossier ) }
        ];
      } else {
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.RELANCE.ERREUR', dossier.numeroDossier ) }
        ];
      }
      this.loading = false;
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.AFFECTATION.MESSAGE.RELANCE.ERREUR', dossier.numeroDossier ) }
      ];
      this.loading = false;
    })

  }

  /**
   * Dupliquer un dossier
   * @param dossier
   * @param codeLivrable
   * @param idAgence
   */
  dupliquer(dossier : Dossier, codeLivrable : string, idAgence : number) {
    this.loading = true;
    this.dossierService.duplicationDossier(dossier.idDossier, codeLivrable, idAgence).subscribe(response => {
      if(response) {
        const message = this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.CLICK_OUVRIR');
        this.message = [
          {severity:'success', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.SUCESS_DUPLICATION_DOSSIER',
              response.numeroDossier, "<a class='messageLink' href='/dossier/fiche/" + response.idDossier +  " '>" + message + "</a>") }
        ];
        this.getListDossierGestionnaire(this.criteres, this.criteres.pagination);
      }
      this.loading = false;
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_DUPLICATION_DOSSIER', dossier.numeroDossier)}
      ];
      this.loading = false;
    })
  }

  modifierLivrable(dossier: Dossier) {
      if(dossier.infoExpertise?.typeLivrable?.code === 'TVAVISDOSSI') {
        this.router.navigateByUrl('/avis-valeur/edition/' + dossier.idDossier);
      }else if(dossier.infoExpertise?.typeLivrable?.code === 'TVRAPPORTXX') {
        this.router.navigateByUrl('/rapport-expertise/edition/' + dossier.idDossier);
      }
  }

  rejeterLivrable(dossier: Dossier) {
    this.loading = true;

    if(dossier.infoExpertise?.typeLivrable?.code === 'TVAVISDOSSI') {
      dossier.etatEnvoi = ConfigConstants.ETAT_ENVOI_INIT;
    }else if(dossier.infoExpertise?.typeLivrable?.code === 'TVRAPPORTXX') {
      this.dossierToRejected = null;
    }

    this.dossierService.rejectLivrable(dossier).subscribe(response => {
      this.message = [
        {severity:'success', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.REJETER.SUCCESS', dossier.numeroDossier.toString()) }
      ];
      this.getListDossierGestionnaire(this.criteres, this.criteres.pagination);
      this.loading = false;
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.REJETER.ERROR',  dossier.numeroDossier.toString())}
      ];
      this.loading = false;
    })
  }

  controlerLivrable(dossier: Dossier) {
      this.loading = true;
      this.dossierService.verouillerDossier(dossier).subscribe( data => {
         if(data) {
           this.router.navigateByUrl("/dossier/fiche/" + dossier.idDossier);
           return;
         }
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_VEROUILLER_DOSSIER', dossier.numeroDossier.toString())}
        ];
        this.loading = false;
      },error => {
        this.message = [
          {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_VEROUILLER_DOSSIER', dossier.numeroDossier.toString())}
        ];
        this.loading = false;
      });
  }

  debloquerDossierVerouiller(dossier : Dossier) {
    this.loading = true;
    this.dossierService.debloquerDossierVerrouiller(dossier).subscribe( data => {
      if(data) {
        this.getListDossierGestionnaire(this.criteres, this.criteres.pagination);
        return;
      }
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_DEBLOQUER_DOSSIER', dossier.numeroDossier.toString())}
      ];
      this.loading = false;
    },error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_DEBLOQUER_DOSSIER', dossier.numeroDossier.toString())}
      ];
      this.loading = false;
    });
  }

  /**
   * Annuler un dossier
   * @param dossier
   */
  annuler(dossier: Dossier) {
    this.loading = true;
    this.dossierService.annulationDossier(dossier).subscribe(response => {
        this.message = [
          {severity:'success', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.SUCESS_ANNULATION_DOSSIER', dossier.numeroDossier.toString()) }
        ];
      this.getListDossierGestionnaire(this.criteres, this.criteres.pagination);
      this.loading = false;
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_ANNULATION_DOSSIER', dossier.numeroDossier.toString())}
      ];
      this.loading = false;
    })
  }

  /**
   * Reactiver un dossier annuler
   * @param dossier
   */
  reactiver(dossier: Dossier) {
    this.dossierService.reactivationDossier(dossier).subscribe(response => {
      this.message = [
        {severity:'success', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.SUCESS_REACTIVATION_DOSSIER', dossier.numeroDossier.toString()) }
      ];
      this.getListDossierGestionnaire(this.criteres, this.criteres.pagination);
      this.loading = false;
    }, error => {
      this.message = [
        {severity:'error', summary:this.translate.getElement('DOSSIER.GESTIONNAIRE.MESSAGE.ERROR_REACTIVATION_DOSSIER', dossier.numeroDossier.toString())}
      ];
      this.loading = false;
    })
  }

  /**
   * retourne tous les dossiers en etat d'affectation
   * @param criteres
   * @param page
   * @param checkEmpty
   */
  getListDossierGestionnaire(criteres: ItemCriteriaResarchDossier, page : Pagination, checkEmpty? : boolean) {
    this.loading = true;
    this.listFolderEmpty = false;

    if(!criteres) {
      criteres = new ItemCriteriaResarchDossier();
    }
    criteres.pagination = page;
    this.dossierService.getDemandeByCriteria(criteres).subscribe( data => {
      this.response = data;
      this.tableHeight = 0;
      this.dossiers = data.content;
      this.dossiers.forEach(d => {
        // Transforme les champs date en véritable objet date
        if (d.dateVisite != null) {
          d.dateVisite = new Date(d.dateVisite);
        }
        if (d.dateContactDossier != null) {
          d.dateContactDossier = new Date(d.dateContactDossier);
        }
        if (d.mission.dateRemiseEnValeurPilote != null) {
          d.mission.dateRemiseEnValeurPilote = new Date(d.mission.dateRemiseEnValeurPilote);
        }
        if (d.mission.dateMissionPrevisionnelle != null) {
          d.mission.dateMissionPrevisionnelle = new Date(d.mission.dateMissionPrevisionnelle);
        }
      });
      this.totalRecords = data.totalElements;
      if(this.totalRecords === 0 && checkEmpty) {
        this.listFolderEmpty = true;
      }
      this.loading = false;
      this. onWindowResize();
    }, error => {
      this.loading = false;
    })
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  showActions(dossier: Dossier) {

  }

  lazyLoad(event: LazyLoadEvent) {
    if(this.firstLazyLoad) {
      // On saute le premier chargement du tableau qui est redondant avec l'initialisation de la page
      this.firstLazyLoad = false;
    } else {
      let page = null;
      if(this.criteres) {
         page  = this.criteres.pagination;
      }

      if (page == null) {
        page = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows, event.sortField, Pagination.getDirection(event.sortOrder));
      } else {
        if (event.sortField != null) {
          page.fieldSort = event.sortField;
        }
        page.direction = Pagination.getDirection(event.sortOrder);
        page.pageNo = this.settings.pagination?.first;
      }
      page.filter = DossierFilter.formatFilters(event);
      this.pagination?.paginator?.changePage(this.settings.pagination.first);
      this.getListDossierGestionnaire(this.criteres, page);
    }

  }

  /**
   * Pour passer à la page suivante
   * @param event
   */
  nextPage(event : any) {
    let page : Pagination = this.criteres.pagination;
    if (page == null) {
      page = new Pagination(event.page, event.rows, "numero", "asc");
    } else {
      page.pageNo = event.page;
      page.pageSize = event.rows;
    }
    this.getListDossierGestionnaire(this.criteres, page);
  }

  /**
   * Exporter le tableau des resultats dans un fichier excel
   */
  exportExcel() {

    let page = new Pagination(this.settings.pagination.first, this.totalRecords, 'numero', "max");
    this.criteres.pagination = page;

    this.loading = true;

    this.dossierService.getDemandeByCriteria(this.criteres).subscribe( data => {

      if(data) {

        import("xlsx").then(xlsx => {
          let dossier = data.content.map(
            dossier => ({
              [this.translate.getElement("DOSSIER.NUM_DOSSIER")] : dossier.numeroDossier,
              [this.translate.getElement("DOSSIER.STATUT")] : new StatutDossierPipe().transform(dossier.statut),
              [this.translate.getElement("DOSSIER.TIERS")] : dossier.tiers?.nomCompletTiers,
              [this.translate.getElement("DOSSIER.DEMANDEUR")] : dossier.demande?.nomCompletDemandeur,
              [this.translate.getElement("DOSSIER.AFFAIRE")] : dossier.client?.nomComplet,
              [this.translate.getElement("DOSSIER.EXPERT")] : dossier.infoExpertise.nomCompletExpert,
              [this.translate.getElement("DOSSIER.ADRESSE_FORM.POSTAL_CODE")] : dossier.adresse.codePostal,
              [this.translate.getElement("DOSSIER.ADRESSE_FORM.CITY")] : dossier.adresse.ville,
              [this.translate.getElement("DOSSIER.VALEUR_VENALE")] : dossier.valeurVenale,
              [this.translate.getElement("DOSSIER.CONTACT")] :  this.datePipe.transform(dossier.dateContactDossier, 'dd/MM/yyyy'),
              [this.translate.getElement("DOSSIER.VISITE")] :  this.datePipe.transform(dossier.dateVisite, 'dd/MM/yyyy'),
              [this.translate.getElement("DOSSIER.LIVRABLE")] :  this.datePipe.transform(dossier.mission.dateRemiseEnValeurPilote, 'dd/MM/yyyy'),
              [this.translate.getElement("DOSSIER.ECHEANCE")] :  this.datePipe.transform(dossier.mission.dateMissionPrevisionnelle, 'dd/MM/yyyy'),
              [this.translate.getElement("DOSSIER.TYPE_LIVRABLE")] : new TypeLivrablePipe().transform(dossier.infoExpertise?.typeLivrable.code)
            })
          );
          const worksheet = xlsx.utils.json_to_sheet(dossier);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          Validation.saveAsExcelFile(excelBuffer, "tableau_dossier_gestionnaire");
          this.loading = false;
        });

      }

    }, error => {

    })


  }


  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  getClasses(dossier : Dossier) {

    if(dossier.etatEnvoi === ConfigConstants.ETAT_ENVOI_PROJET && dossier.statut === StatutDossier.D_ATTENTE_V1) {
      return 'icon-statut-' + dossier.statut + '-etatpeProjet'
    }

    return 'icon-statut-' + dossier.statut;
  }
}
