<nav class="is-secondary fixed" id="menuContextuel">
    <div class="menus-container">

      <div class="" (click)="collapseMenu()" id="collapseButton">
        <i [ngClass]="isCollapse ? 'pi pi-angle-double-left' : 'pi pi-angle-double-right' "></i>
      </div>

      <div class="menu-anchor">
          <p-menu [model]="AnchorItem"></p-menu>
      </div>

      <div class="menu-actions">
        <p-menu [model]="ActionsItem"></p-menu>
      </div>

    </div>
  <p-scrollTop></p-scrollTop>
</nav>
