import {ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent, MenuItem, Message, MessageService, PrimeNGConfig} from 'primeng/api';
import {Dossier} from "../../../core/models/dossiers/dossier";
import {ItemCriteriaResarchDossier} from "../../../core/models/dossiers/item-criteria-resarch-dossier";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ResponsePagination} from "../../../core/models/dossiers/response-pagination";
import {DossierService} from "../../../core/service/dossier.service";
import {LocalStorageRechercheDossierDemandeurService} from "../../../core/service/local-storage/local-storage-recherche-dossier-demandeur.service";
import {LocalStorageService} from "../../../core/service/local-storage/local-storage.service";
import {TranslateService} from "../../../shared/translate/translate.service";
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {TypeLivrablePipe} from "../../../shared/pipes/type-livrable.pipe";
import {Validation} from "../../../shared/providers/validation";
import {PopUpHonorairesComponent} from 'src/app/core/dialog/pop-up-honoraires/pop-up-honoraires.component';
import {PopUpDateComponent} from 'src/app/core/dialog/pop-up-date/pop-up-date.component';
import {ConfigConstants} from 'src/app/core/models/enum/config-constants';
import {AppSettings, Settings} from "../../../app.settings";
import {UserService} from "../../../core/service/user/user.service";
import {Pagination} from 'src/app/core/models/rechercher-tableau/pagination';
import {DossierFilter} from 'src/app/core/models/rechercher-tableau/dossier-filter';
import {PaginationComponent} from 'src/app/shared/pagination/pagination.component';


@Component({
  selector: 'app-recherche-dossier-expert',
  templateUrl: './recherche-expert.component.html',
  styleUrls: ['./recherche-expert.component.scss'],
  providers: [MessageService]
})
export class RechercheDossierExpertComponent implements OnInit {

  @ViewChild(PaginationComponent) pagination : PaginationComponent;

  dossiers: Dossier[] = [];
  criteres: ItemCriteriaResarchDossier = new ItemCriteriaResarchDossier();
  detailToast: string;
  totalRecords : number = 0;
  public settings: Settings;
  loading: boolean;
  header : any;
  infoBulleConflit: any;
  listFolderEmpty : boolean = false;
  display: boolean = false;
  ref: DynamicDialogRef;
  response : ResponsePagination;
  messageAffect: Message[];
  tableHeight : number;
  titleHeader : string;
  firstLazyLoad : boolean = true;

  constructor(
    private dossierService: DossierService,
    private messageService: MessageService,
    private storageCritereRecherche : LocalStorageRechercheDossierDemandeurService,
    private tokenStorgeService : LocalStorageService,
    public translate: TranslateService,
    public dialogService: DialogService,
    private datePipe : DatePipe,
    private route : ActivatedRoute,
    private primengConfig: PrimeNGConfig,
    private storageUser : LocalStorageService,private userService : UserService,
    public appSettings : AppSettings, private router : Router,
    private cdref: ChangeDetectorRef,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {

    this.primengConfig.ripple = true;

    this.header = this.translate.data.DOSSIER.GESTIONNAIRE.HEADER.TITLE;
    this.infoBulleConflit = this.translate.data.DOSSIER.GESTIONNAIRE.INFO.CONFLIT;

    this.quickSearch();
  }

  /**
   * Pour la gestion automatique de la taille du tableau
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    let elem = document.getElementById('tableaux');
    let rect = elem?.getBoundingClientRect();
    this.tableHeight = window.innerHeight - rect?.y;
  }

  async quickSearch() {
    const profiles = await this.userService.getUserConnectedProfilesById();
    if (profiles) {
      this.route.queryParams.subscribe(data => {
        if(data) {
          if(data.typeRecherche === "dashboardCard") {
            this.criteres = new ItemCriteriaResarchDossier();
            this.criteres.statutDossier = ["ENCOURS"];
            this.criteres.typeVue = profiles;
            this.criteres.demandeEncoursExpert = true;
            this.titleHeader = this.translate.getElement('DOSSIER.EXPERT_TAB.HEADER.TITLE');
          }
          else if(data.typeRecherche === "rechercheRapide") {
            this.criteres = new ItemCriteriaResarchDossier(data);
            this.criteres.typeVue = profiles;
            this.titleHeader = this.translate.getElement('DOSSIER.RECHERCHER');
          }else {
            if(this.storageCritereRecherche.getResponseRechercheDossierDemandeur()) {
              this.criteres = JSON.parse(this.storageCritereRecherche.getResponseRechercheDossierDemandeur());
            }
            this.titleHeader = this.translate.getElement('DOSSIER.RECHERCHER');
          }
          let page : Pagination = this.criteres.pagination;
          if (page == null) {
            page = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows, "numero", "asc");
          }
          this.getListDossierExpert(this.criteres, page, true);
        }
      });
    }
  }

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  /**
   * Gestion des actions utilisateur sur chaque dossier en fonction du type de livrable
   * @param dossier
   */
  getActions(dossier: Dossier): MenuItem[] {
    let itemeDateContact : MenuItem = {
      label: this.translate.getElement("DOSSIER.ACTION_TABLE_RESULTA_EXPERT.DATE_CONTACT"),
      icon : 'pi pi-calendar',
      command: () => {this.editDateContact(dossier)}
    };
    let itemDateVisite: MenuItem = {
      label: this.translate.getElement("DOSSIER.ACTION_TABLE_RESULTA_EXPERT.DATE_VISITE"),
      icon : 'pi pi-calendar',
      command: () => {this.editDateVisite(dossier)}
    };
    let itemModifierHonoraire: MenuItem = {
      label: this.translate.getElement("DOSSIER.ACTION_TABLE_RESULTA_EXPERT.MODIFY_HONORAIRE"),
      icon : 'pi pi-euro',
      command: () => {this.modifierHonoraire(dossier)}
    };
    let itemEditerLivrable: MenuItem = {
      label: this.translate.getElement("DOSSIER.ACTION_TABLE_RESULTA_EXPERT.EDITER_LIVRABLE"),
      icon : 'pi pi-pencil',
      command: () => {
        this.modifierLivrable(dossier)
      }
    };

    if (dossier.infoExpertise?.typeLivrable?.code === 'TVRAPPORTXX') {
      let resp : MenuItem[] = [] ;
      resp.push(itemeDateContact)
      if(dossier.dateContactDossier) {
        resp.push(itemDateVisite);
        if(dossier.dateVisite) {
          resp.push(itemModifierHonoraire);
          resp.push(itemEditerLivrable);
        }
      }

      return resp;
    }

    if (dossier.infoExpertise?.typeLivrable?.code === 'TVAVISDOSSI') {
      return [itemModifierHonoraire, itemEditerLivrable]

    }
    return [];
  }

  /**
   * Editer et modifier la date de contact
   * @param dossier
   */
  editDateContact(dossier: Dossier) {
    this.ref = this.dialogService.open(PopUpDateComponent, {
      header: this.translate.getElement('DOSSIER.POPUP.DATE.CONTACT.TITRE'),
      width: '45vw',
      baseZIndex: 10000,
      data : {'dossier':dossier, 'type':ConfigConstants.DATE_CONTACT},
      styleClass : 'p-dynamic-dialog'
    });
  }

  /**
   * Editer et modifier la date de visite
   * @param dossier
   */
  editDateVisite(dossier: Dossier) {
    this.ref = this.dialogService.open(PopUpDateComponent, {
      header: this.translate.getElement('DOSSIER.POPUP.DATE.VISITE.TITRE'),
      width: '45vw',
      baseZIndex: 10000,
      data : {'dossier':dossier, 'type':ConfigConstants.DATE_VISITE},
      styleClass : 'p-dynamic-dialog'
    });
  }

  /**
   * modifier l'honaire
   * @param dossier
   */
  modifierHonoraire(dossier: Dossier) {
    this.ref = this.dialogService.open(PopUpHonorairesComponent, {
      header: this.translate.getElement('DOSSIER.POPUP.HONORAIRES.TITRE'),
      width: '35vw',
      data : dossier,
      styleClass : 'p-dynamic-dialog'
    });
  }

  modifierLivrable(dossier: Dossier) {
    if(dossier.infoExpertise?.typeLivrable?.code === 'TVAVISDOSSI') {
      this.router.navigateByUrl('/avis-valeur/edition/' + dossier.idDossier);
    }else if(dossier.infoExpertise?.typeLivrable?.code === 'TVRAPPORTXX') {
      this.router.navigateByUrl('/rapport-expertise/edition/' + dossier.idDossier);
    }
  }

  /**
   * retourne les dossiers qui repondent aux critères de recherche
   * @param pageNo
   * @param pageSize
   */
  getListDossierExpert(criteres: ItemCriteriaResarchDossier, page : Pagination, checkEmpty? : boolean) {
    this.loading = true;
    this.listFolderEmpty = false;
    criteres.pagination = page;
    this.dossierService.getDemandeByCriteria(criteres).subscribe( data => {
      this.response = data;
      this.tableHeight = 0;
      this.dossiers = data.content;
      this.dossiers.forEach(d => {

        if (d.dateVisite != null) {
          d.dateVisite = new Date(d.dateVisite);
        }
        if (d.dateContactDossier != null) {
          d.dateContactDossier = new Date(d.dateContactDossier);
        }

        if (d.mission.dateMissionPrevisionnelle != null) {
          d.mission.dateMissionPrevisionnelle = new Date(d.mission.dateMissionPrevisionnelle);
        }
      });
      this.totalRecords = data.totalElements;
      if(this.totalRecords === 0 && checkEmpty) {
        this.listFolderEmpty = true;
      }
      this.loading = false;
      this. onWindowResize();
    }, error => {
      this.loading = false;
    })
  }



  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  /**
   * Pour passer à la page suivante
   * @param event
   */
  nextPage(event : any) {
    let page : Pagination = this.criteres.pagination;
      if (page == null) {
        page = new Pagination(event.page, event.rows, "numero", "asc");
      } else {
        page.pageNo = event.page;
        page.pageSize = event.rows;
      }
    this.getListDossierExpert(this.criteres, page);
  }

  lazyLoad(event: LazyLoadEvent) {
    if(this.firstLazyLoad) {
      // On saute le premier chargement du tableau qui est redondant avec l'initialisation de la page
      this.firstLazyLoad = false;
    } else {
      let page : Pagination = this.criteres.pagination;
      if (page == null) {
        page = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows, event.sortField, Pagination.getDirection(event.sortOrder));
      } else {
        if (event.sortField != null) {
          page.fieldSort = event.sortField;
        }
        page.direction = Pagination.getDirection(event.sortOrder);
        page.pageNo = this.settings.pagination?.first;
      }
      page.filter = DossierFilter.formatFilters(event);
      this.pagination?.paginator?.changePage(this.settings.pagination.first);
      this.getListDossierExpert(this.criteres, page);
    }

  }

  /**
   * Exporter le tableau des resultats dans un fichier excel
   */
  exportExcel() {

    let page = new Pagination(this.settings.pagination?.first, this.totalRecords, 'numero', "asc");
    this.criteres.pagination = page;

    this.dossierService.getDemandeByCriteria(this.criteres).subscribe( data => {

      if(data) {

        import("xlsx").then(xlsx => {
          let dossier = data.content.map(
            dossier => ({
              [this.translate.getElement("DOSSIER.NUM_DOSSIER")] : dossier.numeroDossier,
              [this.translate.getElement("DOSSIER.TYPE_LIVRABLE")] : new TypeLivrablePipe().transform(dossier.infoExpertise?.typeLivrable.code),
              [this.translate.getElement("DOSSIER.TIERS")] : dossier.tiers?.nomCompletTiers,
              [this.translate.getElement("DOSSIER.DEMANDEUR")] : dossier.demande?.nomCompletDemandeur,
              [this.translate.getElement("DOSSIER.AFFAIRE")] : dossier.client?.nomComplet,
              [this.translate.getElement("DOSSIER.ADRESSE")] : dossier.adresse?.nomVoie,
              [this.translate.getElement("DOSSIER.ADRESSE_FORM.POSTAL_CODE")] : dossier.adresse.codePostal,
              [this.translate.getElement("DOSSIER.ADRESSE_FORM.CITY")] : dossier.adresse.ville,
              [this.translate.getElement("DOSSIER.CONTACT")] :  this.datePipe.transform(dossier.dateContactDossier, 'dd/MM/yyyy'),
              [this.translate.getElement("DOSSIER.VISITE")] :  this.datePipe.transform(dossier.dateVisite, 'dd/MM/yyyy'),
              [this.translate.getElement("DOSSIER.ECHEANCE")] :  this.datePipe.transform(dossier.mission.dateMissionPrevisionnelle, 'dd/MM/yyyy'),
            })
          );
          const worksheet = xlsx.utils.json_to_sheet(dossier);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          Validation.saveAsExcelFile(excelBuffer, "tableau_dossier_expert");
        });

      }

    }, error => {

    })


  }
}
