import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStorageRechercheDossierDemandeurService} from "../../../core/service/local-storage/local-storage-recherche-dossier-demandeur.service";
import {DossierService} from "../../../core/service/dossier.service";
import {ItemCriteriaResarchDossier} from "../../../core/models/dossiers/item-criteria-resarch-dossier";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ResponsePagination} from "../../../core/models/dossiers/response-pagination";
import {Dossier} from "../../../core/models/dossiers/dossier";
import {Document} from "../../../core/models/document/document.model";
import {DatePipe} from "@angular/common";
import {Validation} from "../../../shared/providers/validation";
import {TypeLivrablePipe} from "../../../shared/pipes/type-livrable.pipe";
import {StatutDossierPipe} from "../../../shared/pipes/statut-dossier.pipe";
import {TranslateService} from "../../../shared/translate/translate.service";
import {LazyLoadEvent, Message} from "primeng/api";
import {LocalStorageService} from "../../../core/service/local-storage/local-storage.service";
import {AppSettings, Settings} from "../../../app.settings";
import {UserService} from "../../../core/service/user/user.service";
import {Pagination} from 'src/app/core/models/rechercher-tableau/pagination';
import {DossierFilter} from 'src/app/core/models/rechercher-tableau/dossier-filter';
import {PaginationComponent} from 'src/app/shared/pagination/pagination.component';

@Component({
  selector: 'app-recherche-demande',
  templateUrl: './recherche-demande.component.html',
  styleUrls: ['./recherche-demande.component.scss']
})
export class RechercheDemandeComponent implements OnInit {

  demandeEncoursAgent : number = 0;
  infoMessage : string;
  item : ItemCriteriaResarchDossier;
  listFolderEmpty : boolean;
  display: boolean = false;
  dossiers: Dossier[] = [];
  ref: DynamicDialogRef;
  totalRecords : number = 0;
  response : ResponsePagination;

  loading: boolean;

  @ViewChild(PaginationComponent) pagination : PaginationComponent;

  numeroDossier : string;
  numeroContrat : string;
  nomClient : string;

  tableHeight : number;

  errorMessage: Message[];
  public settings: Settings;
  titleHeader : string;

  firstLazyLoad: boolean = true;

  /**
   * Pour la gestion automatique de la taille du tableau
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    let elem = document.getElementById('tableaux');
    let rect = elem?.getBoundingClientRect();
    this.tableHeight = window.innerHeight - rect?.y;
  }

  constructor(
    private route: ActivatedRoute,
    private storageCritereRecherche : LocalStorageRechercheDossierDemandeurService,
    private dossierService : DossierService,
    public dialogService: DialogService,
    private datePipe : DatePipe,
    private router : Router,
    private translate : TranslateService,
    private storageUser : LocalStorageService,
    public appSettings : AppSettings,
    private userService : UserService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.loading = true;

    this.quickSearch();

  }

  lazyLoad(event: LazyLoadEvent) {
    if(this.firstLazyLoad) {
      // On saute le premier chargement du tableau qui est redondant avec l'initialisation de la page
      this.firstLazyLoad = false;
    } else {
      let page : Pagination = this.item.pagination;
      if (page == null) {
        page = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows, event.sortField, Pagination.getDirection(event.sortOrder));
      } else {
        if (event.sortField != null) {
          page.fieldSort = event.sortField;
        }
        page.direction = Pagination.getDirection(event.sortOrder);
        page.pageNo = this.settings.pagination?.first;
      }
      page.filter = DossierFilter.formatFilters(event);
      this.pagination?.paginator?.changePage(this.settings.pagination.first);
      this.resultatRechercheListDossierDemandeur(this.item, page);
    }

  }

  /**
   * recherche rapide et avancée
   */
  async quickSearch() {
    const profiles = await this.userService.getUserConnectedProfilesById();
    if(profiles) {
      this.route.queryParams.subscribe(data => {
        if (data.typeRecherche === "dashboardCard") {
          this.item = new ItemCriteriaResarchDossier();
          this.item.statutDossier = ["A_AFFECTER", "ENCOURS", "D_ATTENTE_V1"];
          this.item.typeVue = profiles;
          this.titleHeader = this.translate.getElement('DOSSIER.DEMANDE.RECHERCHE.HEADER.TITLE');
        } else if (data.typeRecherche === "rechercheRapide") {
          this.item = new ItemCriteriaResarchDossier(data);
          this.item.typeVue = profiles;
          this.titleHeader = this.translate.getElement('DOSSIER.RECHERCHER');
        } else {
          let itemCritereRechercheDossier = this.storageCritereRecherche.getResponseRechercheDossierDemandeur();
          if (itemCritereRechercheDossier !== "undefined") {
            this.item = JSON.parse(itemCritereRechercheDossier);
          }
          this.titleHeader = this.translate.getElement('DOSSIER.RECHERCHER');
        }
        let page : Pagination = new Pagination(this.settings.pagination?.first, this.settings.pagination?.rows, "id", "asc");
        this.resultatRechercheListDossierDemandeur(this.item, page, true);
      });
    }

  }

  /**
   * retourne les dossiers qui repondent aux critères de recherche
   * @param item
   * @param page
   * @param rows
   */
  resultatRechercheListDossierDemandeur(item : ItemCriteriaResarchDossier, page : Pagination, checkEmpty? : boolean) {
    this.listFolderEmpty = false;
    this.loading = true;
    this.item = item;
    if(item) {
    item.pagination = page;
    this.dossierService.getDemandeByCriteria(item).subscribe(data => {
        if(data) {
          this.tableHeight = 0;
          this.response = data;
          this.dossiers = data.content;
          this.totalRecords = data.totalElements;
          this.demandeEncoursAgent = this.totalRecords;

          if(this.totalRecords <= 0 && checkEmpty) {
            this.listFolderEmpty = true;
          }
          this.loading = false;
          this. onWindowResize();

        }
      },

  error => {
      if(error.status === 401) {
        this.router.navigateByUrl("/role-denied");
      }
        this.loading = false;
      })
    }else {
      this.listFolderEmpty = true;
    }
  }

  /**
   * Pour passer à la page suivante
   * @param event
   */
  nextPage(event : any) {
    let page : Pagination = this.item.pagination;
      if (page == null) {
        page = new Pagination(event.page, event.rows, "numero", "asc");
      } else {
        page.pageNo = event.page;
        page.pageSize = event.rows;
      }
    this.resultatRechercheListDossierDemandeur(this.item, page);
  }

  /**
   * Exporter le tableau des resultats dans un fichier excel
   */
  exportExcel() {
    let item = this.item;
    item.pagination.pageNo = this.settings.pagination?.first;
    item.pagination.pageSize = this.totalRecords;
    this.dossierService.getDemandeByCriteria(item).subscribe(data => {
        if(data) {
          this.response = data;
          import("xlsx").then(xlsx => {
            let dossier = data.content.map(
              dossier => ({
                [this.translate.getElement("DOSSIER.NUM_CONTRAT")] : dossier.numeroContrat,
                [this.translate.getElement("DOSSIER.NUM_DOSSIER")] : dossier.numeroDossier,
                [this.translate.getElement("DOSSIER.STATUT")] : new StatutDossierPipe().transform(dossier.statut),
                [this.translate.getElement("DOSSIER.TIERS")] : dossier.tiers.nomCompletTiers,
                [this.translate.getElement("DOSSIER.AFFAIRE")] : dossier.client.nomMoral ? dossier.client.nomMoral : dossier.client.nom + ' ' + dossier.client.prenom,
                [this.translate.getElement("DOSSIER.ADRESSE_FORM.POSTAL_CODE")] : dossier.adresse.codePostal,
                [this.translate.getElement("DOSSIER.ADRESSE_FORM.CITY")] : dossier.adresse.ville,
                [this.translate.getElement("DOSSIER.CONTACT")] :  this.datePipe.transform(dossier.dateContactDossier, 'dd/MM/yyyy'),
                [this.translate.getElement("DOSSIER.VISITE")] : this.datePipe.transform(dossier.dateVisite, 'dd/MM/yyyy'),
                [this.translate.getElement("DOSSIER.TYPE_LIVRABLE")] : new TypeLivrablePipe().transform(dossier.infoExpertise.typeLivrable.code)
              })
            );
            const worksheet = xlsx.utils.json_to_sheet(dossier);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            Validation.saveAsExcelFile(excelBuffer, "tableau_recherche_demande");
          });
        }
      },

      error => {
        this.errorMessage = [ {severity:'error', summary: this.translate.getElement("DOSSIER.MESSAGE.ERROR.EXPORT_EXCEL") }];
      })

  }

  addDocument(documents: Document[]): void {}

}
