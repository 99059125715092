import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {StatutDossier} from "../../models/enum/statut-dossier";
import {DossierService} from "../../service/dossier.service";
import {ItemCriteriaResarchDossier} from "../../models/dossiers/item-criteria-resarch-dossier";
import {Message} from "primeng/api";
import {LocalStorageRechercheDossierDemandeurService} from "../../service/local-storage/local-storage-recherche-dossier-demandeur.service";
import {TranslateService} from "../../../shared/translate/translate.service";
import {LocalStorageService} from "../../service/local-storage/local-storage.service";
import {ProfilesUser} from "../../models/user/profiles-user";
import {UserService} from "../../service/user/user.service";
import {User} from "../../models/user/user.model";
import { StatutDossierPipe } from 'src/app/shared/pipes/statut-dossier.pipe';

@Component({
  selector: 'app-recherche-avancee-dossier',
  templateUrl: './recherche-avancee-dossier.component.html',
  styleUrls: ['./recherche-avancee-dossier.component.scss']
})
export class RechercheAvanceeDossierComponent implements OnInit {

  form : FormGroup;

  submitted = true;
  statuts : any[];

  invalidDates: Array<Date>

  messageERROR: Message[];

  typeDeVues : any[] = [];

  typeDeVue : string [] = [];

  hiddenTypeVue : boolean = true;

  show : boolean = true;
  profiles : ProfilesUser[];
  role : string[];

  user : User;


  constructor(
    public ref: DynamicDialogRef, private dossierService : DossierService,
    public config: DynamicDialogConfig, private statutDossierpipe : StatutDossierPipe,
    private storageCritereRecherche : LocalStorageRechercheDossierDemandeurService,
    private translate : TranslateService, private userService : UserService,
    private fb : FormBuilder, private storageUser : LocalStorageService
  ) {
    //Pour gerer(ajouter la class) le scroll dans la modal
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('p-dynamic-dialog-scrollable');
  }

  async ngOnInit() {

    this.getUserConnected();

    this.profiles = await this.userService.getUserConnectedProfilesById();

    this.statuts = Object.keys(StatutDossier).map(key => ({name: StatutDossier[key], value: new StatutDossierPipe().transform(StatutDossier[key])}));

    if(this.profiles) {
      this.role = Object.values(this.profiles).map(item => String(item));
    }

    this.vueByProfil();
    this.initForm();
  }

  getUserConnected() {
    this.userService.getUserConnectedByToken().then(userConnected => {
      this.user = userConnected;
    });
  }

  /**
   * Lance la recherche avancée et redirige l'utilisateur vers le tableau de resultat en fonction de son profil
   */
  recherche() {

    const data = this.form.value;

    const typeDevue = this.typeDeVue;
    let item : ItemCriteriaResarchDossier;

    if(typeDevue) {
      item = this.initItemCriteriaResarchDossier(data.contenu, typeDevue, data.numeroDossier, data.numeroContrat);
    }

    if( data.numeroDossier != null &&  data.numeroDossier.trim() &&  data.numeroDossier.trim().length < 5) {
      this.messageERROR = [ {severity:'error', summary: this.translate.getElement('DOSSIER.MESSAGE.ERROR.CARACTERE_NUMERO_DOSSIER', '5')}];
      return;
    }

    if(!item ||
      (this.isNullUndefEmptyStr(item.nomClient) && this.isNullUndefEmptyStr(item.prenomClient) && this.isNullUndefEmptyStr(item.codeTypeLivrable)
        && this.isNullUndefEmptyStr(item.numeroDossier) && this.isNullUndefEmptyStr(item.numeroContrat)
        && this.isNullUndefEmptyStr(item.periodeDateDebut?.toDateString()) && this.isNullUndefEmptyStr(item.periodeDateFin?.toDateString())
        && this.isNullUndefEmptyStr(item.adresseBien.codePostal?.toString()) && this.isNullUndefEmptyStr(item.adresseBien.nomVoie)
        && this.isNullUndefEmptyStr(item.adresseBien?.numero?.toString()) && this.isNullUndefEmptyStr(item.adresseBien.ville) && this.isNullUndefEmptyStr(item.adresseBien.complement)
        && (item.statutDossier === null || item.statutDossier.length === 0) && this.isNullUndefEmptyStr(item.expert?.toString()) &&this.isNullUndefEmptyStr(item.tiers?.toString())) ) {
      this.messageERROR = [
        {severity:'warn', summary: this.translate.getElement('DOSSIER.ADVANCED_SEARCH.MESSAGE.ERROR')  }
      ];
      return;
    }

    this.storageCritereRecherche.setCritereRechercheDemande(item);


    if (item.typeVue.includes(ProfilesUser.AGENT)) {
      this.redirection(item,"/dossier/demande/suivi"  );
    } else if (item.typeVue.includes(ProfilesUser.ASSISTANTE) || item.typeVue.includes(ProfilesUser.ANIMATEUR_RESEAU)) {
      this.redirection(item,"/dossier/recherche/gestionnaire");
    } else if (item.typeVue.includes(ProfilesUser.EXPERT_EXTERNE) || item.typeVue.includes(ProfilesUser.EXPERT_INTERNE)) {
      this.redirection(item ,"/dossier/recherche/expert"  );
    }


  }

  /**
   * Initialisation du type de vue en fonction du profil de l'utilisateur
   */
  vueByProfil() {
    if(this.profiles) {
      this.typeDeVues = [
        {name : this.translate.getElement('DOSSIER.DEMANDEUR'), profil : [ProfilesUser.AGENT]},
        {name : this.translate.getElement('DOSSIER.EXPERT'), profil : [ProfilesUser.EXPERT_EXTERNE, ProfilesUser.EXPERT_INTERNE]},
        {name : this.translate.getElement('DOSSIER.ADMINISTRATOR_GESTIONNAIRE'), profil : [ProfilesUser.ASSISTANTE, ProfilesUser.ANIMATEUR_RESEAU]}
      ];
      if(this.profiles.includes(ProfilesUser.ADMINISTRATEUR) || this.profiles.includes(ProfilesUser.ADMINISTRATEUR_TECHNIQUE)) {
        this.hiddenTypeVue = false;
        this.show = false;
      }else if(this.profiles.includes(ProfilesUser.AGENT)) {
        this.typeDeVue = this.profiles;
      }else  if(this.profiles.includes(ProfilesUser.EXPERT_INTERNE) || this.profiles.includes(ProfilesUser.EXPERT_EXTERNE)) {
        this.typeDeVue = this.profiles;
      }else if(this.profiles.includes(ProfilesUser.ASSISTANTE) || this.profiles.includes(ProfilesUser.ANIMATEUR_RESEAU)) {
        this.typeDeVue = this.profiles;
      }
    }

  }

  /**
   * initialisation du formulaire
   */
  initForm() {
    this.form = this.fb.group({
      typeVue : new FormControl(this.typeDeVue),
      numeroDossier : new FormControl(null),
      numeroContrat : new FormControl(null),
      contenu : this.creationFormGroupe(),
    });
  }

  creationFormGroupe() : FormGroup {
    return this.fb.group({
      client : this.fb.group({
        nomClient: new FormControl(null),
        prenomClient: new FormControl(null),
      }),
      typeDoc : this.fb.group ({
        codeTypeLivrable: new FormControl(null)
      }),
      statut : this.fb.group({
        statutDossier :new FormControl(null)
      }),

      periode : this.fb.group({
        periodeDateDebut : new FormControl(null),
        periodeDateFin : new FormControl(null),
      }),

      agence :  this.fb.group({
        elargir : new FormControl(false),
      }),

      adresseBien : new FormGroup({
        numero : new FormControl(null),
        nomVoie : new FormControl(null),
        codePostal : new FormControl(null),
        complement : new FormControl(null),
        ville : new FormControl(null)
      }),
      expert :  [null],
      tiers : [null]
    })
  }

  initItemCriteriaResarchDossier(data : any, typeVue : string[], numeroDossier?: string, numeroContrat?: string) : ItemCriteriaResarchDossier{

    let item :ItemCriteriaResarchDossier = new ItemCriteriaResarchDossier();
    item.nomClient = data.client.nomClient;
    item.prenomClient = data.client.prenomClient;
    item.statutDossier = data.statut?.statutDossier?.name ? [data.statut?.statutDossier?.name] : [];
    item.periodeDateDebut = data.periode.periodeDateDebut;
    item.periodeDateFin = data.periode.periodeDateFin;
    item.adresseBien = data.adresseBien;
    item.codeTypeLivrable = data.typeDoc.codeTypeLivrable;
    item.typeVue = typeVue;
    item.numeroContrat = numeroContrat;
    item.numeroDossier = numeroDossier;
    if(typeVue && (typeVue.includes(ProfilesUser.ASSISTANTE) || typeVue.includes(ProfilesUser.ANIMATEUR_RESEAU))) {
      item.expert = data.expert?.id;
      item.tiers = data.tiers?.id;
    }

    if(typeVue && typeVue.includes(ProfilesUser.AGENT) && !this.user.agentSuperviseur) {
      item.expandAgencySearch = data.agence.elargir;
    }
    return item;
  }

  isNullUndefEmptyStr(value : string) {
    return value === null || value === undefined || value?.trim()?.length === 0;
  }


  /**
   *
   * @param item : critères de recherche
   * @param configData : recherche avancée à partir de l'ecran d'affichage du resultat
   * @param url : recherche avancée à partir du tableau de bord
   */
  redirection(item : ItemCriteriaResarchDossier,  url : string) {
      this.close({url : url, params : item});
  }

  close(data? : any) {
    this.ref.close(data);
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  showAdvanceSearch() {
    this.show = !this.show;
  }

  typeProfil(event : any) {
    this.typeDeVue = event.value.profil;
  }


}
