import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import * as FileSaver from "file-saver";

export class Validation {

  /**
   * Verifier la correspondance entre les mots de passe
   * @param controlName
   * @param matchingControlName
   */
  static confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({confirmedValidator: true});
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  /**
   * controle de validation pour la politique du mot de passe pendant la saisie
   * @param regex
   * @param error
   */
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // si le contrôle est vide, ne renvoie aucune erreur
        return null;
      }

      // tester la valeur du contrôle par rapport à l'expression rationnelle fournie
      const valid = regex.test(control.value);

      // si vrai, ne renvoie aucune erreur (pas d'erreur), sinon renvoie l'erreur passée dans le deuxième paramètre
      return valid ? null : error;
    };
  }

  /**
   * Cette expression régulière appliquera ces règles :
   * Au moins 8 caractères
   * Au moins 1 chiffre
   * Au moins une lettre majuscule
   * Au moins une lettre miniscule
   * Au moins un caractère spécial
   * @param text
   * @return true ou false
   */
  static  validationPassword(text : string) {

    const regex = new RegExp(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]){1,})(?!.*\s).{8,}$/);

    return regex.test(text);
  }


  /**
   * Validation code postal français
   * @param cp
   */
  static regexPostalCodeFR(cp : string) {

    const regex = new RegExp(/^((((?!00|20)([0-8][0-9])|(9[0-5]))[0-9]{3})|(((97[1-6])|(98[4,6,7,8]))[0-9]{2})|(20[0,1,2,6][0-9]{2}))$/);

    return regex.test(cp);

  }

  static saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  static patternValidatorMaxLengthCaracter(maxlength : number, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      if (!control.value) {
        // si le contrôle est vide, ne renvoie aucune erreur
        return null;
      }

      //Remplace les codes html trouvés dans le commentaire par une chaine vide
      let plainText = control?.value.replace(/<[^>]*>/g, '');

      // si vrai, ne renvoie aucune erreur (pas d'erreur), sinon renvoie l'erreur passée dans le deuxième paramètre
      return plainText.length <= maxlength ? null : error;
    }
  }

  static patternValidatorEmptyCaracter(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      if (!control.value) {
        // si le contrôle est vide, ne renvoie aucune erreur
        return null;
      }

      //Remplace les codes html trouvés dans le commentaire par une chaine vide
      let plainText = control?.value.replace(/<[^>]*>/g, '').trim();

      // si vrai, ne renvoie aucune erreur (pas d'erreur), sinon renvoie l'erreur passée dans le deuxième paramètre
      return plainText.length <= 0 ? error : null;
    }
  }

  static validatoionEmptyCaracterFielTextWithShaping(texte : String): boolean {


      if (!texte) {
        // si le contrôle est vide, ne renvoie aucune erreur
        return null;
      }

      //Remplace les codes html trouvés dans le commentaire par une chaine vide
      let plainText = texte.replace(/<[^>]*>/g, '').trim();

      // si vrai, ne renvoie aucune erreur (pas d'erreur), sinon renvoie l'erreur passée dans le deuxième paramètre
      return plainText.length <= 0;

  }

}
