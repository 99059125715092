import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RapportExpertise} from "../../../models/rapport-expertise/rapport-expertise";
import {AbstractControl, FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {Message, MessageService} from "primeng/api";
import {RapportExpertiseObservableService} from "../../../../shared/observable/rapport-expertise-observable.service";
import {RapportExpertiseService} from "../../../service/rapport-expertise.service";
import {DatePipe} from "@angular/common";
import {TranslateService} from "../../../../shared/translate/translate.service";
import {Validation} from "../../../../shared/providers/validation";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-conclusion-rapport',
  templateUrl: './conclusion-rapport.component.html',
  styleUrls: ['./conclusion-rapport.component.scss']
})
export class ConclusionRapportComponent implements OnInit, OnDestroy {

  @Input()
  formGroupName: string;

  @Input()
  submitted : boolean;

  @Input()
  submittedRequired : boolean;

  @Input()
  submittedRequiredSynthese : boolean;

  @Input()
  rapport : RapportExpertise;

  form: FormGroup;

  typeValeur : any;

  regimeFiscalVenal : any[];
  regimeFiscalLocatif : any[];

  surface : number = 0;

  liquidite : any;

  delaiCommercialisation : any;

  nbDelaiPrecision : number = 0;
  msgErrorMaxcaract : Message[];
  etablissements : any[];
  tauxFiscalite : any[];

  currentDate = new Date();


  valeurVenteForcee : number;
  vvfIncluse : number;
  vapIncluse : number;
  vfafIncluse : number;
  regimeFv : any;
  regimeFvap : any;
  regimeFFa : any;
  regimeVl : any;

  vvPrixrM2 : number;
  vapPrixrM2 : number;
  vlPrixrM2 : number;
  vfaPrixrM2 : number;

  delaiPrecisionRequired : boolean;
  abbatValueError : boolean;

  fullNameExpert : string;

  private subscriptionTypeValeur : Subscription;
  private subscriptionSurface : Subscription;
  private subscriptionDelaiCommercialissation : Subscription;
  private subscriptionLiquidite : Subscription;
  private subscriptionFullNameExpert : Subscription;



  constructor(
    private fb : FormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private rootFormGroup: FormGroupDirective,
    private cdref: ChangeDetectorRef,
    private message: MessageService,
    private translate : TranslateService,
    private datePipe: DatePipe,
    private rapportExpertiseObservableService : RapportExpertiseObservableService,
    private rapportExpertiseService : RapportExpertiseService,
  ) { }

  ngOnInit(): void {

    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    this.getAllRegimeFiscalVenal();
    this.getAllRegimeFiscalLocatif();
    this.getAllVilleImpl();
    this.getTauxFiscalite();


    this.calculValeurVenteForcee(this.form?.value?.decote, this.form?.value?.valeurVenal);
    this.calculValeurVenaleFiscalite(this.form?.value?.valeurVenal, this.form?.value?.tauxFiscaliteVenal?.taux);
    this.calculValeurVenaleFiscaliteApresTravaux(this.form?.value?.valeurApresTravaux, this.form?.value?.tauxFiscaliteApresTravaux?.taux);
    this.calculValeurFutureAchevement(this.form?.value?.valeurFuturAchevement, this.form?.value?.tauxFiscaliteEtatFuturAchevement?.taux);

    this.getRapportObservableValue();

    this.nbDelaiPrecision = this.form?.value?.delaiPrecision ? this.form?.value?.delaiPrecision?.replace(/<[^>]*>/g, '')?.length : 0;
    this.msgErrorMaxcaract =[ {key : 'delaiPrecision', severity:'error',
      summary:  this.translate.getElement("RAPPORT_EXPERTISE.MESSAGE.ERROR_CARACTERE_COMMENTAIRE", '500')}];

    this.regimeFv = this.form?.value?.regimeFiscalVenal;
    this.regimeFvap = this.form?.value?.regimefiscalApresTraveaux;
    this.regimeFFa = this.form?.value?.regimefiscalFuturAchevement;
    this.regimeVl = this.form?.value?.regimeFiscalLocatif;

    if(this.rapport.nomExpert) {
      this.fullNameExpert = this.rapport.nomExpert + ' ' + this.rapport.prenomExpert;
    }

    this.subscriptionFullNameExpert = this.rapportExpertiseObservableService.getFullNameExpert().subscribe( data => {
      if(data) {
        this.fullNameExpert = data;
      }
    })
  }

  ngOnDestroy() {
    this.subscriptionTypeValeur?.unsubscribe();
    this.subscriptionLiquidite?.unsubscribe();
    this.subscriptionSurface?.unsubscribe();
    this.subscriptionDelaiCommercialissation?.unsubscribe();
    this.subscriptionFullNameExpert?.unsubscribe();
  }


  getRapportObservableValue() {
    this.subscriptionTypeValeur = this.rapportExpertiseObservableService.getTypeValeur().subscribe( data =>{
      if(data) {
        this.getTypeValeur(data);
        this.requiredDelaiPrecision();
      }
    })

    this.subscriptionSurface = this.rapportExpertiseObservableService.getSurface().subscribe( data =>{
      if(data) {
        this.surface = data;
        this.setValuePrixM2(this.typeValeur, data);
      }
    })

    this.subscriptionLiquidite = this.rapportExpertiseObservableService.getLiquidite().subscribe( data =>{
      if(data) {
        this.liquidite = data;
      }
    })

    this.subscriptionDelaiCommercialissation = this.rapportExpertiseObservableService.getDelaiCommercialissation().subscribe( data =>{

      if(data) {
        this.delaiCommercialisation = data;
        this.requiredDelaiPrecision();
      }

    })

  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getAllRegimeFiscalLocatif()  {
    this.rapportExpertiseService.getAllRegimeFiscalLocatif().subscribe(
      data => {
        if(data) {
          this.regimeFiscalLocatif = data;
        }
      }
    )
  }

  getAllRegimeFiscalVenal()  {
    this.rapportExpertiseService.getAllRegimeFiscalVenal().subscribe(
      data => {
        if(data) {
          this.regimeFiscalVenal = data;
        }
      }
    )
  }
  getAllVilleImpl()  {
    this.rapportExpertiseService.getAllVilleImpl().subscribe(
      data => {
        if(data) {
          this.etablissements = data;
        }
      },error => {
      }
    )
  }

  getTauxFiscalite()  {
    this.rapportExpertiseService.getTauxFiscalite().subscribe(
      data => {
        if(data) {
          this.tauxFiscalite = data;
        }
      }
    )
  }

  setRequiredValeurVenal() {

    if(this.typeValeur?.idTypeValeur === 1 || this.typeValeur?.idTypeValeur === 3 || this.typeValeur?.idTypeValeur === 5 ) {

      this.form.controls['valeurVenal'].setValidators(Validators.required);
      this.form.controls['regimeFiscalVenal'].setValidators(Validators.required);
      this.form.controls['tauxFiscaliteVenal'].setValidators(Validators.required);

      if (this.rapport?.typeTiers === 'TTBANQUE') {
        this.form.controls['decote'].setValidators(Validators.required);
      } else {
        this.form.controls['decote'].setValidators(null);
      }
    }else {
      this.form.controls['valeurVenal'].setValidators(null);
      this.form.controls['regimeFiscalVenal'].setValidators(null);
      this.form.controls['decote'].setValidators(null);
      this.form.controls['tauxFiscaliteVenal'].setValidators(null);
    }

    this.form.controls['tauxFiscaliteVenal'].updateValueAndValidity();
    this.form.controls['valeurVenal'].updateValueAndValidity();
    this.form.controls['regimeFiscalVenal'].updateValueAndValidity();
  }

  setRequiredValeurLocative() {

    if(this.typeValeur?.idTypeValeur === 2 || this.typeValeur?.idTypeValeur === 5) {
      this.form.controls['valeurLocative'].setValidators(Validators.required);
      this.form.controls['regimeFiscalLocatif'].setValidators(Validators.required);
    }else {
      this.form.controls['valeurLocative'].setValidators(null);
      this.form.controls['regimeFiscalLocatif'].setValidators(null);
    }

    this.form.controls['valeurLocative'].updateValueAndValidity();
    this.form.controls['regimeFiscalLocatif'].updateValueAndValidity();

  }

  setRequiredValeurApresTraveaux() {
    if(this.typeValeur?.idTypeValeur === 3 ) {
      this.form.controls['valeurApresTravaux'].setValidators(Validators.required);
      this.form.controls['regimefiscalApresTraveaux'].setValidators(Validators.required);
      this.form.controls['tauxFiscaliteApresTravaux'].setValidators(Validators.required);
    }else {
      this.form.controls['valeurApresTravaux'].setValidators(null);
      this.form.controls['regimefiscalApresTraveaux'].setValidators(null);
      this.form.controls['tauxFiscaliteApresTravaux'].setValidators(null);
    }
    this.form.controls['valeurApresTravaux'].updateValueAndValidity();
    this.form.controls['regimefiscalApresTraveaux'].updateValueAndValidity();
    this.form.controls['tauxFiscaliteApresTravaux'].updateValueAndValidity();

  }

  setRequiredValeurFuturAchevement() {

    if(this.typeValeur?.idTypeValeur === 4) {
      this.form.controls['valeurFuturAchevement'].setValidators(Validators.required);
      this.form.controls['regimefiscalFuturAchevement'].setValidators(Validators.required);
      this.form.controls['tauxFiscaliteEtatFuturAchevement'].setValidators(Validators.required);
    }else {
      this.form.controls['valeurFuturAchevement'].setValidators(null);
      this.form.controls['regimefiscalFuturAchevement'].setValidators(null);
      this.form.controls['tauxFiscaliteEtatFuturAchevement'].setValidators(null);
    }

    this.form.controls['valeurFuturAchevement'].updateValueAndValidity();
    this.form.controls['regimefiscalFuturAchevement'].updateValueAndValidity();
    this.form.controls['tauxFiscaliteEtatFuturAchevement'].updateValueAndValidity();

  }


  getTypeValeur(valeur : any)  {
    if(valeur) {

      this.typeValeur = {idTypeValeur : valeur?.idTypeValeur, libelle : valeur.libelle, };

      switch (this.typeValeur?.idTypeValeur) {
        case 1 :
          let prixM2VV = this.calculPrixrM2(this.surface, this.form?.value?.valeurVenal);
          this.rapportExpertiseObservableService.sendValeurVenale({
            value :this.form?.value?.valeurVenal, regime : this.form?.value?.regimeFiscalVenal?.libelleCourt,
            ratio : prixM2VV
          })
          this.form.get('prixM2ValeurVenale').setValue(prixM2VV);
          this.typeValeur['label1'] = 'Valeur vénale hors fiscalité';


          this.setRequiredValeurVenal();
          this.setRequiredValeurApresTraveaux();
          this.setRequiredValeurFuturAchevement();
          this.setRequiredValeurLocative();

          return this.typeValeur;
        case 2 :
          let prixM2VL = this.calculPrixrM2(this.surface, this.form?.value?.valeurLocative);
          this.rapportExpertiseObservableService.sendValeurLocative({
            value :this.form?.value?.valeurLocative, regime : this.form?.value?.regimeFiscalLocatif?.libelleCourt,
            ratio : prixM2VL
          })
          this.form.get('prixM2ValeurLocative').setValue(prixM2VL);
          this.typeValeur['label1'] = 'Valeur locative';

          this.setRequiredValeurVenal();
          this.setRequiredValeurApresTraveaux();
          this.setRequiredValeurFuturAchevement();
          this.setRequiredValeurLocative();
          return this.typeValeur;
        case 3 :
          this.typeValeur['label1'] = 'Valeur vénale hors fiscalité';
          this.typeValeur['label2'] = 'Valeur après travaux';
          let prixM2VVHF = this.calculPrixrM2(this.surface, this.form?.value?.valeurVenal);
          this.rapportExpertiseObservableService.sendValeurVenale({
            value :this.form?.value?.valeurVenal, regime : this.form?.value?.regimeFiscalVenal?.libelleCourt,
            ratio : prixM2VVHF
          })
          this.form.get('prixM2ValeurVenale').setValue(prixM2VVHF);

          let prixM2VAT = this.calculPrixrM2(this.surface, this.form?.value?.valeurApresTravaux);
          this.rapportExpertiseObservableService.sendValeurApresTraveaux({
            value :this.form?.value?.valeurApresTravaux, regime : this.form?.value?.regimefiscalApresTraveaux?.libelleCourt,
            ratio : prixM2VAT
          })
          this.form.get('prixM2ValeurApresTravaux').setValue(prixM2VAT);

          this.setRequiredValeurVenal();
          this.setRequiredValeurApresTraveaux();
          this.setRequiredValeurFuturAchevement();
          this.setRequiredValeurLocative();
          return this.typeValeur;
        case 4 :
          this.typeValeur['label1'] = 'une valeur en état futur d\'achèvement';
          let prixM2VEFA = this.calculPrixrM2(this.surface, this.form?.value?.valeurFuturAchevement);
          this.rapportExpertiseObservableService.sendValeurApresTraveaux({
            value :this.form?.value?.valeurFuturAchevement, regime : this.form?.value?.regimefiscalFuturAchevement?.libelleCourt,
            ratio : prixM2VEFA
          })
          this.form.get('prixM2ValeurFuturAchevement').setValue(prixM2VEFA);

          this.setRequiredValeurVenal();
          this.setRequiredValeurApresTraveaux();
          this.setRequiredValeurFuturAchevement();
          this.setRequiredValeurLocative();
          return this.typeValeur;
        case 5 :
          this.typeValeur['label1'] = 'Valeur vénale hors fiscalité';
          this.typeValeur['label2'] = 'Valeur locative';
          let prixM2VV5 = this.calculPrixrM2(this.surface, this.form?.value?.valeurVenal);
          this.rapportExpertiseObservableService.sendValeurVenale({
            value :this.form?.value?.valeurVenal, regime : this.form?.value?.regimeFiscalVenal?.libelleCourt,
            ratio : prixM2VV5
          })
          this.form.get('prixM2ValeurVenale').setValue(prixM2VV5);

          let prixM2VL5 = this.calculPrixrM2(this.surface, this.form?.value?.valeurLocative);
          this.rapportExpertiseObservableService.sendValeurLocative({
            value :this.form?.value?.valeurLocative, regime : this.form?.value?.regimeFiscalLocatif?.libelleCourt,
            ratio : prixM2VL5
          })
          this.form.get('prixM2ValeurLocative').setValue(prixM2VL5);

          this.setRequiredValeurVenal();
          this.setRequiredValeurApresTraveaux();
          this.setRequiredValeurFuturAchevement();
          this.setRequiredValeurLocative();

          return this.typeValeur;
        default :
          return null;

      }
    }

  }

  /**
   * si on change la valeur(valeur vénale, locative, après traveaux, futur achevement), ça recalcul son prix m2
   * @param event
   * @param typeVal
   */
  calculPrixM2IfValueChanged(event : any, typeVal : number) {
    let value = event.value;
    switch (typeVal) {
      case 1 :
        this.vvPrixrM2 = this.calculPrixrM2(this.surface, value)
        this.form.get('prixM2ValeurVenale').setValue(this.vvPrixrM2);
        this.calculValeurVenteForcee(this.form?.value?.decote, value);
        this.calculValeurVenaleFiscalite(value, this.form?.value?.tauxFiscaliteVenal?.taux);
        this.rapportExpertiseObservableService.sendValeurVenale({
          value :value, regime : this.form?.value?.regimeFiscalVenal?.libelleCourt, ratio : this.vvPrixrM2
        })
        break;
      case 2 :
        this.vlPrixrM2 = this.calculPrixrM2(this.surface, value)
        this.form.get('prixM2ValeurLocative').setValue(this.vlPrixrM2);
        this.rapportExpertiseObservableService.sendValeurLocative({
          value : value, regime : this.regimeVl?.libelleCourt, ratio : this.vlPrixrM2
        })
        break;
      case 3 :
        this.vapPrixrM2 = this.calculPrixrM2(this.surface, value)
        this.form.get('prixM2ValeurApresTravaux').setValue(this.vapPrixrM2);
        this.calculValeurVenaleFiscaliteApresTravaux(value, this.form?.value?.tauxFiscaliteApresTravaux?.taux)
        this.rapportExpertiseObservableService.sendValeurApresTraveaux({
          value : value, regime : this.regimeFvap?.libelleCourt, ratio : this.vapPrixrM2
        })
        break;
      case 4 :
        this.vfaPrixrM2 = this.calculPrixrM2(this.surface, value)
        this.form.get('prixM2ValeurFuturAchevement').setValue(this.vfaPrixrM2);
        this.calculValeurFutureAchevement(value, this.form?.value?.tauxFiscaliteEtatFuturAchevement?.taux)
        this.rapportExpertiseObservableService.sendValeurFutureAchevement({
          value : value, regime : this.regimeFFa?.libelleCourt, ratio : this.vfaPrixrM2
        })
        break;
      default :
        break;
    }
  }

  /**
   * recalcul le prix
   * @param typeValeur
   * @param surface
   */
  setValuePrixM2(typeValeur : any, surface : number) {
    if(typeValeur) {
      switch (typeValeur?.idTypeValeur) {
        case 1 :
          this.form.get('prixM2ValeurVenale').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurVenal));
          this.rapportExpertiseObservableService.sendValeurVenale({
            value :this.form?.value?.valeurVenal, regime : this.form?.value?.regimeFiscalVenal?.libelleCourt,
            ratio : this.calculPrixrM2(surface, this.form?.value?.valeurVenal)
          })
          break;
        case 2 :
          this.form.get('prixM2ValeurLocative').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurLocative));
          this.rapportExpertiseObservableService.sendValeurLocative({
            value : this.form?.value?.valeurLocative, regime : this.regimeVl?.libelleCourt,
            ratio : this.calculPrixrM2(surface, this.form?.value?.valeurLocative)
          })
          break;
        case 3 :
          this.form.get('prixM2ValeurVenale').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurVenal));
          this.rapportExpertiseObservableService.sendValeurVenale({
            value :this.form?.value?.valeurVenal, regime : this.form?.value?.regimeFiscalVenal?.libelleCourt,
            ratio : this.calculPrixrM2(surface, this.form?.value?.valeurVenal)
          })
          this.form.get('prixM2ValeurApresTravaux').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurApresTravaux));
          this.rapportExpertiseObservableService.sendValeurApresTraveaux({
            value :  this.form?.value?.valeurApresTravaux, regime : this.form?.value?.regimefiscalApresTraveaux?.libelleCourt,
            ratio : this.calculPrixrM2(surface, this.form?.value?.valeurApresTravaux)
          })
          break;
        case 4 :
          this.form.get('prixM2ValeurFuturAchevement').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurFuturAchevement));
          this.rapportExpertiseObservableService.sendValeurFutureAchevement({
            value : this.form?.value?.valeurFuturAchevement, regime : this.form?.value?.regimefiscalFuturAchevement?.libelleCourt,
            ratio : this.calculPrixrM2(surface, this.form?.value?.valeurFuturAchevement)
          })
          break;
        case 5 :
          this.form.get('prixM2ValeurVenale').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurVenal));
          this.rapportExpertiseObservableService.sendValeurVenale({
            value :this.form?.value?.valeurVenal, regime : this.form?.value?.regimeFiscalVenal?.libelleCourt,
            ratio : this.calculPrixrM2(surface, this.form?.value?.valeurVenal)
          })
          this.form.get('prixM2ValeurLocative').setValue(this.calculPrixrM2(surface, this.form?.value?.valeurLocative));
          this.rapportExpertiseObservableService.sendValeurLocative({
            value : this.form?.value?.valeurLocative, regime : this.form?.value?.regimeFiscalLocatif?.libelleCourt,
            ratio : this.calculPrixrM2(surface, this.form?.value?.valeurLocative)
          })
          break;
        default :
          break;
      }
    }
  }

  /**
   *  * Calcul de la valeur vente forcée
   *  Si abattement>0 alors valeur en vente forcée = valeur vénale * (1- abattement * 0.01 )
   * @param abattement
   * @param valeurVenal
   */
  calculValeurVenteForcee(abattement : number, valeurVenal : number) {
    if(abattement && abattement > 0 && valeurVenal >= 0) {
      this.valeurVenteForcee = valeurVenal * (1 - abattement * 0.01);
    }else {
      this.valeurVenteForcee = 0;
    }
    this.form.get('valeurJuridique').setValue( this.valeurVenteForcee)
    this.rapportExpertiseObservableService.sendValeurVenteForcee(this.valeurVenteForcee);
  }

  /**
   * calcul valeur vénale fiscalité incluse
   * si valeur vénale hors fiscalité > 0 et taux > 0, alors Valeur vénale fiscalité incluse = valeur vénale hors fiscalité * (1+taux*0,01)
   * @param vvhf
   * @param taux
   */
  calculValeurVenaleFiscalite(vvhf : number, taux : number) {
    if(vvhf && taux) {
      this.vvfIncluse = vvhf * (1 + taux * 0.01);
      this.rapportExpertiseObservableService.sendValeurVenteForcee(this.vvfIncluse);
    }
  }

  /**
   *  calcul valeur après travaux fiscalité incluse
   * @param vap
   * @param taux
   */
  calculValeurVenaleFiscaliteApresTravaux(vap : number, taux : number) {
    if(vap && taux) {
      this.vapIncluse = vap * (1 + taux * 0.01);
    }
  }

  /**
   *  calcul valeur future achevement fiscalité incluse
   * @param vfa
   * @param taux
   */
  calculValeurFutureAchevement(vfa : number, taux : number) {
    if(vfa && taux) {
      this.vfafIncluse = vfa * (1 + taux * 0.01);
    }
  }

  /**
   * detection changement abattement
   * @param event
   */
  abattementChange(event: any) {
    this.abbatValueError = event.value < 0 || event.value > 100;
    let valeurVenal = this.form?.value?.valeurVenal;
    this.calculValeurVenteForcee(event.value, valeurVenal);
  }

  /**
   * calcul du prix m2
   * @param surf :  surface
   * @param valeur :  valeur
   */
  calculPrixrM2(surf : number, valeur : number) : number {
    if(surf && surf > 0 && valeur >= 0) {
      this.surface = surf;
      return valeur / this.surface;
    }
    return  null;
  }

  numDelaiPrecisionTextChange(event: any) {
      this.nbDelaiPrecision = event?.htmlValue ? event?.htmlValue?.replace(/<[^>]*>/g, '')?.trim()?.length : 0;
  }

  changeRegimeFiscalVenal(event: any) {
    this.regimeFv = event.value;
    this.rapportExpertiseObservableService.sendValeurVenale({
      value : this.form?.value?.valeurVenal, regime : this.regimeFv?.libelleCourt, ratio : this.vvPrixrM2
    })
  }

  changeTauxFiscalite(event: any) {
    this.calculValeurVenaleFiscalite(this.form?.value?.valeurVenal, event?.value?.taux);
  }

  changeTauxFiscaliteApresTravaux(event: any) {
    this.calculValeurVenaleFiscaliteApresTravaux(this.form?.value?.valeurApresTravaux, event?.value?.taux);
  }

  changeRegimeFiscalFuturAchevement(event: any) {
    this.regimeFFa = event.value;
    this.rapportExpertiseObservableService.sendValeurFutureAchevement({
      value : this.form?.value?.valeurFuturAchevement, regime : this.regimeFFa?.libelleCourt, ratio : this.vfaPrixrM2
    })
  }

  changeRegimeFiscalApresTravaux(event: any) {
    this.regimeFvap = event.value;
    this.rapportExpertiseObservableService.sendValeurApresTraveaux({
      value : this.form?.value?.valeurApresTravaux, regime : this.regimeFvap?.libelleCourt, ratio : this.vvPrixrM2
    })
  }

  changeRegimeLocatif(event: any) {
    this.regimeVl = event.value;
    this.rapportExpertiseObservableService.sendValeurLocative({
      value : this.form?.value?.valeurLocative, regime : this.regimeVl?.libelleCourt, ratio : this.vlPrixrM2
    })
  }

  changeTauxFiscaliteFuturAchevement(event: any) {
    this.calculValeurFutureAchevement(this.form?.value?.valeurFuturAchevement, event?.value?.taux)
  }

  requiredDelaiPrecision() {
    if(this.delaiCommercialisation && (this.typeValeur?.idTypeValeur === 1 || this.typeValeur?.idTypeValeur === 3 || this.typeValeur?.idTypeValeur === 5)) {
      if(this.delaiCommercialisation?.idDelaiCommercialisation === 1) {
        this.delaiPrecisionRequired = false;
        this.form.controls['delaiPrecision'].setValidators(Validation.patternValidatorMaxLengthCaracter(500, {hasError: true}));
        this.form.controls['delaiPrecision'].updateValueAndValidity();
      }else {
        this.delaiPrecisionRequired = true;
        this.form.controls['delaiPrecision'].setValidators([
          Validators.required, Validation.patternValidatorMaxLengthCaracter(500, {hasError: true}),
          Validation.patternValidatorEmptyCaracter({hasEmptyCaracterError: true})
        ]);
        this.form.controls['delaiPrecision'].updateValueAndValidity();
      }
    }else {
      this.form.controls['delaiPrecision'].setValidators(null);
      this.form.controls['delaiPrecision'].updateValueAndValidity();
    }
  }

}
